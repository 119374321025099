import { useState, useEffect } from "react";
import { ProductCard } from "./ProductCard";
import { Spinner } from "./Spinner";
import productsList from "../pages/list.json";

export function ProductsGrid() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a small loading delay to prevent flash of loading state
    setTimeout(() => {
      setProducts(productsList);
      setLoading(false);
    }, 300);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {products.map((data) => (
        <ProductCard key={data.id} product={data} />
      ))}
    </ul>
  );
}
