import { ProductsGrid } from "../components/ProductsGrid";
import { FaYoutube, FaInstagram } from "react-icons/fa";
import { useState } from "react";

export function LandingPage() {
  const [language, setLanguage] = useState("en");

  const content = {
    en: {
      title: "Select an AI Sapiens",
      description: `The AI agents we deploy are designed to intelligently adapt and evolve based on the data and interactions you provide. We call this the Vertical Robot Brain—a system that intuitively suggests content, articles, and ideas tailored to your unique interests. For those who value time and want to fast-track their success, this is a transformative tool. Unlike commercial large language models bound by predefined agendas, our models are uncensored and prioritize aligning with your specific needs and interactions, ensuring a truly personalized and unrestricted experience.`,
      followSamantha: "Follow Samantha on Social Media! 🌟",
      followText: "Follow samantha in youtube and instagram",
    },
    es: {
      title: "Selecciona un AI Sapiens",
      description: `Los agentes de IA que implementamos están diseñados para adaptarse y evolucionar de manera inteligente según los datos e interacciones que proporcionas. Llamamos a esto el Cerebro Robot Vertical—un sistema que sugiere intuitivamente contenido, artículos e ideas adaptados a tus intereses únicos. Para aquellos que valoran el tiempo y desean acelerar su éxito, esta es una herramienta transformadora. A diferencia de los modelos de lenguaje comerciales limitados por agendas predefinidas, nuestros modelos no están censurados y priorizan alinearse con tus necesidades e interacciones específicas, garantizando una experiencia verdaderamente personalizada y sin restricciones.`,
      followSamantha: "¡Sigue a Samantha en Redes Sociales! 🌟",
      followText: "Sigue a Samantha en YouTube e Instagram",
    },
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white flex flex-col items-center justify-center p-4 pt-24">
      {/* Language Switcher - Moved and repositioned */}
      <div className="w-full max-w-6xl flex justify-end mb-4">
        <div className="space-x-2">
          <button
            onClick={() => setLanguage("en")}
            className={`px-3 py-1 rounded ${
              language === "en"
                ? "bg-purple-600 text-white"
                : "bg-gray-700 text-gray-300"
            }`}
          >
            EN
          </button>
          <button
            onClick={() => setLanguage("es")}
            className={`px-3 py-1 rounded ${
              language === "es"
                ? "bg-purple-600 text-white"
                : "bg-gray-700 text-gray-300"
            }`}
          >
            ES
          </button>
        </div>
      </div>

      {/* Hero Section */}
      <div className="w-full max-w-6xl mb-12 text-center">
        <h1 className="text-5xl font-bold mb-8 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
          {content[language].title}
        </h1>
        <p className="text-lg leading-relaxed mb-8 text-gray-300 max-w-4xl mx-auto">
          {content[language].description}
        </p>
      </div>

      {/* Products Grid */}
      <div className="w-full max-w-6xl bg-gray-800/50 backdrop-blur-lg shadow-xl rounded-xl p-8 mb-8">
        <ProductsGrid />
      </div>

      {/* Social Banner for Samantha */}
      <div className="w-full max-w-6xl bg-gradient-to-r from-purple-600 to-pink-600 p-6 rounded-xl shadow-2xl">
        <div className="text-center">
          <h2 className="text-3xl font-bold mb-4">
            {content[language].followSamantha}
          </h2>
          <p className="mb-6 text-lg">{content[language].followText}</p>
          <div className="flex justify-center space-x-8">
            <a
              href="https://www.youtube.com/@samanthahilbert/videos"
              target="_blank"
              rel="noopener noreferrer"
              className="group transform hover:scale-110 transition-all duration-300"
            >
              <FaYoutube
                size={48}
                className="text-white group-hover:text-red-500"
              />
              <span className="block text-sm mt-2 opacity-90 group-hover:opacity-100">
                YouTube
              </span>
            </a>
            <a
              href="https://www.instagram.com/sam.ai.ht/"
              target="_blank"
              rel="noopener noreferrer"
              className="group transform hover:scale-110 transition-all duration-300"
            >
              <FaInstagram
                size={48}
                className="text-white group-hover:text-pink-400"
              />
              <span className="block text-sm mt-2 opacity-90 group-hover:opacity-100">
                Instagram
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
