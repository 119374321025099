import React from "react";
import { FaPaperPlane } from "react-icons/fa";
import FileUpload from "../FileUpload";

const MessageInput = ({
  input,
  handleInputChange,
  handleKeyPress,
  handlePostRequest,
  handleFileUpload,
  attachedFile,
  setAttachedFile,
  userId,
  chatBotId,
}) => {
  return (
    <div className="flex mt-4 w-full">
      <div className="flex-grow flex flex-col">
        {attachedFile && (
          <div className="mb-2 px-3 py-2 bg-gray-700 rounded-lg flex items-center justify-between">
            <span className="text-sm text-gray-200">{attachedFile.name}</span>
            <button
              onClick={() => setAttachedFile(null)}
              className="text-gray-400 hover:text-gray-200"
            >
              ×
            </button>
          </div>
        )}
        <div className="flex items-center bg-gray-800 bg-opacity-50 rounded-lg shadow-lg border border-gray-700">
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Type a message"
            className="flex-grow p-3 bg-transparent text-white placeholder-gray-400 focus:outline-none"
          />
          <FileUpload
            onFileUpload={handleFileUpload}
            userId={userId}
            chatBotId={chatBotId}
          />
          <button
            onClick={handlePostRequest}
            disabled={!input.trim() && !attachedFile}
            className={`p-3 rounded-r-lg transition-all duration-200 flex items-center justify-center ${
              input.trim() || attachedFile
                ? "text-blue-500 hover:text-blue-400"
                : "text-gray-500"
            }`}
          >
            <FaPaperPlane size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
