import React from "react";
import jarvisImage from "../../img/jarvis_ai_sapiens_small.png";
import samImage from "../../img/sam_ai_sapiens_small.jpg";
import { FaUser } from "react-icons/fa";

const MessageList = ({ messages, messagesEndRef, chatBotId }) => {
  const parseMessage = (messageText) => {
    // Regular expression to match file information
    const fileRegex = /\[File: (.*?)\]\((.*?)\)/;
    const match = messageText.match(fileRegex);

    if (match) {
      const fileName = match[1];
      const fileUrl = match[2];
      // Check if file is PDF by extension
      const isPdf = fileName.toLowerCase().endsWith(".pdf");

      return {
        text: messageText.replace(match[0], "").trim(),
        attachment: {
          name: fileName,
          url: fileUrl,
          type: isPdf ? "application/pdf" : "unknown",
          isPDF: isPdf,
        },
      };
    }

    return { text: messageText, attachment: null };
  };

  const renderMessage = (message) => {
    const parsedMessage = parseMessage(message.msg);
    const isPDF =
      parsedMessage.attachment?.type === "application/pdf" ||
      parsedMessage.attachment?.isPDF;

    return (
      <div
        key={message.id}
        className={`flex items-start ${
          message.type === "human" ? "justify-end" : "justify-start"
        } mb-4 gap-2`}
      >
        {message.type !== "human" && (
          <img
            src={chatBotId === "0" ? jarvisImage : samImage}
            alt="AI Avatar"
            className="w-8 h-8 rounded-full object-cover"
          />
        )}
        <div
          className={`max-w-[80%] rounded-lg p-4 ${
            message.type === "human"
              ? "bg-blue-600 text-white"
              : "bg-gray-800 text-white"
          }`}
        >
          {parsedMessage.text}
          {parsedMessage.attachment && isPDF && (
            <div className="mt-2 flex items-center gap-2 p-2 rounded-lg bg-gray-700">
              <div className="w-8 h-8 flex items-center justify-center bg-pink-500 rounded-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <a
                href={parsedMessage.attachment.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm text-blue-400 hover:underline"
              >
                {parsedMessage.attachment.name}
              </a>
            </div>
          )}
        </div>
        {message.type === "human" && (
          <div className="w-8 h-8 rounded-full bg-gray-600 flex items-center justify-center">
            <FaUser className="text-white text-sm" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex-grow overflow-y-auto p-4">
      {messages.map(renderMessage)}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageList;
