import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Spinner } from "../components/Spinner";
import { getProductImg } from "../utils/getProductImg";
import productsList from "./list.json";
import { Link } from "react-router-dom";

export function ProductDetails() {
  const { productId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    setIsLoading(true);
    const foundProduct = productsList.find((p) => p.id === parseInt(productId));
    setProduct(foundProduct);
    setIsLoading(false);
  }, [productId]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!product) {
    return <div className="text-white">Product not found</div>;
  }

  const imageUrl = getProductImg(product.image, 200);
  return (
    <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center p-2">
      <div className="absolute top-24 right-4 space-x-2">
        <button
          onClick={() => setLanguage("en")}
          className={`px-3 py-1 rounded ${
            language === "en"
              ? "bg-purple-600 text-white"
              : "bg-gray-700 text-gray-300"
          }`}
        >
          EN
        </button>
        <button
          onClick={() => setLanguage("es")}
          className={`px-3 py-1 rounded ${
            language === "es"
              ? "bg-purple-600 text-white"
              : "bg-gray-700 text-gray-300"
          }`}
        >
          ES
        </button>
      </div>

      <div className="bg-gray-800 shadow-md rounded-lg p-6 w-full max-w-4xl flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <img
          className="w-full md:w-1/3 rounded-lg"
          src={imageUrl}
          alt={product.name}
        />
        <div className="flex flex-col justify-between w-full md:w-2/3">
          <div>
            <p className="text-2xl font-bold mb-2">
              <strong>AI Sapiens:</strong> {product.name}
            </p>
            <p className="mb-4">
              <strong>
                {language === "en" ? "Description:" : "Descripción:"}
              </strong>{" "}
              {product.description[language]}
            </p>
          </div>
          <Link to="/controlpanel">
            <button className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded mt-4">
              {language === "en" ? "Interact" : "Interactuar"}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
