import { Auth } from "aws-amplify";

// New file to manage WebSocket connection
export class WebSocketManager {
  constructor(userId) {
    this.socket = null;
    this.userId = userId;
    this.messageHandlers = new Set();
    this.isConnected = false;
  }

  async connect() {
    try {
      // Get the current session token from Amplify Auth
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();

      // Add the token as a query parameter
      const wsUrl = `wss://1xvkrgxs6c.execute-api.us-east-1.amazonaws.com/staging?token=${idToken}`;

      this.socket = new WebSocket(wsUrl);

      this.socket.onopen = () => {
        console.log("Connected to WebSocket");
        this.isConnected = true;
      };

      this.socket.onmessage = (event) => {
        try {
          const response = JSON.parse(event.data);
          console.log("WebSocket received message:", response);

          // Only process message for display if it's not an enqueue action
          if (response.action !== "enqueue") {
            const formattedResponse = {
              type: "ai_response",
              answer:
                response.data?.response ||
                response.message ||
                response.answer ||
                response.body,
            };
            this.messageHandlers.forEach((handler) =>
              handler(formattedResponse)
            );
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      };

      this.socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      this.socket.onclose = () => {
        console.log("WebSocket disconnected");
        this.isConnected = false;
        setTimeout(() => this.connect(), 5000);
      };
    } catch (error) {
      console.error("Error establishing WebSocket connection:", error);
    }
  }

  addMessageHandler(handler) {
    this.messageHandlers.add(handler);
  }

  removeMessageHandler(handler) {
    this.messageHandlers.delete(handler);
  }

  sendMessage(message) {
    if (this.socket && this.isConnected) {
      const formattedMessage = {
        action: "sendmessage",
        human_input: message.human_input,
        bot_id: message.bot_id,
        user_id: message.user_id,
        file_url: message.file_url || null,
        file_type: message.file_type || null,
      };
      this.socket.send(JSON.stringify(formattedMessage));
    } else {
      console.error("WebSocket is not connected");
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
    }
  }
}
