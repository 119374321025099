import axios from "axios";
// Function to make POST requests
const API1 = "https://ae4u5khoi2.execute-api.us-east-1.amazonaws.com/prod/";

const APIr = "https://ae4u5khoi2.execute-api.us-east-1.amazonaws.com/prod/";

// const data = {
//   input: {
//     human_input: input,
//   },
//   config: {
//     configurable: {
//       conversation_id: 122,
//       user_id: document.cookie.split("=")[1],
//       chatBotId,
//     },
//   },
// };

export async function post_ai(path, data, bot_id) {
  let API = API1;

  const result = await fetch(API + path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    body: JSON.stringify(data),
  });

  const response = await result.json();

  // Log the success message to console only
  if (response.message === "Job submitted successfully") {
    console.log(response.message);
    // Return only the job_id
    return { job_id: response.job_id };
  }

  return response;
}

// Function to retrieve messages
export async function retrieve_messages(user_id, num_messages, bot_id) {
  // if conversation_id is 1 then const API = "https://api.samanthabot.com/"
  let API;

  API = APIr;

  const path = "retrieve_messages";
  const data = {
    user_id: user_id,
    num_messages: num_messages,
    bot_id: bot_id,
  };

  const result = await fetch(API + path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    body: JSON.stringify(data),
    // credentials: "include", // Include cookies in the request
  });

  return await result.json();
}

// Function to check the job status
export const check_job_status = async (jobId, chatBotId) => {
  const response = await axios.get(API1 + `status/${jobId}`);
  return response.data;
};
