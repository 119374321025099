import React, { useState, useEffect, useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import jarvisImage from "../img/jarvis_ai_sapiens_small.png";
import samImage from "../img/sam_ai_sapiens_small.jpg";

export default function Sidebar({
  subscribedProducts = [],
  setSelectedChatBotId,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const sidebarRef = useRef(null);
  const buttonRef = useRef(null);

  // Chatbot names mapping
  const chatbotNames = {
    0: "Jarvis",
    1: "Samantha",
  };

  // Add chatbot images mapping
  const chatbotImages = {
    0: jarvisImage,
    1: samImage,
  };

  // Handle clicks outside the sidebar
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Function to update `isMobile` based on the screen size
  const checkIsMobile = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  // Remove the mobile check and always close sidebar
  const handleChatBotSelection = (productId) => {
    setSelectedChatBotId(productId);
    setIsOpen(false);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        onClick={toggleSidebar}
        className="fixed top-5 left-4 text-xl text-white hover:text-gray-300 p-2 focus:outline-none z-50 transition-colors duration-200"
      >
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>

      <div
        ref={sidebarRef}
        className={`fixed top-0 left-0 h-full bg-gray-900 p-6 transition-transform duration-300 ease-in-out transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } w-72 z-[60] shadow-xl border-r border-gray-800`}
      >
        <div className="mt-16">
          <h2 className="text-2xl font-semibold mb-6 text-white">
            AI Assistants
          </h2>
          <div className="space-y-2">
            {subscribedProducts.length > 0 ? (
              subscribedProducts.map((productId) => (
                <div
                  key={productId}
                  onClick={() => handleChatBotSelection(productId)}
                  className="flex items-center space-x-3 p-3 rounded-lg cursor-pointer transition-all duration-200 hover:bg-gray-800"
                >
                  <div className="w-10 h-10 rounded-full overflow-hidden">
                    <img
                      src={chatbotImages[productId]}
                      alt={chatbotNames[productId]}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div>
                    <h3 className="font-medium text-white">
                      {chatbotNames[productId] || `ChatBot ${productId}`}
                    </h3>
                    <p className="text-sm text-gray-400">
                      {productId === 0 ? "General Assistant" : "AI Sapiens"}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="p-4 text-center text-gray-400">
                No available chatbots.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
