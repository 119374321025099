import React, { useState } from "react";

const translations = {
  en: {
    title: "Contact Us",
    description:
      "Whether you're interested in investing, have suggestions for improvement, or just want to get in touch, we'd love to hear from you. Please indicate in your message if you're interested in investment opportunities or have recommendations for our platform.",
    name: "Name",
    email: "Email",
    reasonForContact: "Reason for Contact",
    selectReason: "Select a reason",
    investment: "Investment Inquiry",
    recommendation: "Recommendation/Suggestion",
    other: "Other",
    message: "Message",
    messagePlaceholder: "Please provide details about your inquiry...",
    send: "Send Message",
    sending: "Sending...",
    success: "Message sent successfully!",
    error: "Failed to send message. Please try again.",
    changeLanguage: "Cambiar a Español",
  },
  es: {
    title: "Contáctenos",
    description:
      "Ya sea que esté interesado en invertir, tenga sugerencias de mejora o simplemente quiera ponerse en contacto, nos encantaría saber de usted. Por favor, indique en su mensaje si está interesado en oportunidades de inversión o tiene recomendaciones para nuestra plataforma.",
    name: "Nombre",
    email: "Correo Electrónico",
    reasonForContact: "Motivo de Contacto",
    selectReason: "Seleccione un motivo",
    investment: "Consulta de Inversión",
    recommendation: "Recomendación/Sugerencia",
    other: "Otro",
    message: "Mensaje",
    messagePlaceholder: "Por favor, proporcione detalles sobre su consulta...",
    send: "Enviar Mensaje",
    sending: "Enviando...",
    success: "¡Mensaje enviado con éxito!",
    error: "Error al enviar el mensaje. Por favor, intente de nuevo.",
    changeLanguage: "Change to English",
  },
};

export function Contact() {
  const [language, setLanguage] = useState("en");
  const t = translations[language];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    contactType: "",
  });
  const [status, setStatus] = useState("");

  const toggleLanguage = () => {
    setLanguage(language === "en" ? "es" : "en");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("sending");

    try {
      const response = await fetch("https://formspree.io/f/xjkvoyjy", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, language }),
      });

      if (response.ok) {
        setStatus("success");
        setFormData({ name: "", email: "", message: "", contactType: "" });
      } else {
        setStatus("error");
      }
    } catch (error) {
      setStatus("error");
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center justify-center p-4 pt-20">
      <div className="w-full max-w-2xl bg-gray-800 shadow-md rounded-lg p-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">{t.title}</h1>
          <button
            onClick={toggleLanguage}
            className="text-sm bg-gray-700 hover:bg-gray-600 px-3 py-1 rounded-md transition duration-300"
          >
            {t.changeLanguage}
          </button>
        </div>

        <p className="text-gray-300 mb-6">{t.description}</p>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium mb-2">
              {t.name}
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium mb-2">
              {t.email}
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label
              htmlFor="contactType"
              className="block text-sm font-medium mb-2"
            >
              {t.reasonForContact}
            </label>
            <select
              id="contactType"
              name="contactType"
              value={formData.contactType}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">{t.selectReason}</option>
              <option value="investment">{t.investment}</option>
              <option value="recommendation">{t.recommendation}</option>
              <option value="other">{t.other}</option>
            </select>
          </div>

          <div>
            <label htmlFor="message" className="block text-sm font-medium mb-2">
              {t.message}
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              rows="5"
              placeholder={t.messagePlaceholder}
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <button
            type="submit"
            disabled={status === "sending"}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md transition duration-300"
          >
            {status === "sending" ? t.sending : t.send}
          </button>

          {status === "success" && (
            <p className="text-green-400 text-center">{t.success}</p>
          )}
          {status === "error" && (
            <p className="text-red-400 text-center">{t.error}</p>
          )}
        </form>
      </div>
    </div>
  );
}
