import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ControlPanel from "./pages/ControlPanel";
import { ProductDetails } from "./pages/ProductDetails";
import { LandingPage } from "./pages/LandingPage";
import PrivateRoute from "./components/PrivateRoute";
import ChatInterface from "./pages/ChatInterface";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import NavBar from "./components/NavBar";
import SubMngmt from "./components/SubMgmt"; // Import the SubMngmt component
import { Contact } from "./pages/Contact";

Amplify.configure(awsconfig);

export function App() {
  return (
    <Authenticator.Provider>
      <Router>
        <div className="min-h-screen bg-gray-900 text-white flex flex-col">
          <NavBar /> {/* Use the Navbar component here */}
          <main>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/controlpanel" element={<ControlPanel />} />
              <Route path="/product/:productId" element={<ProductDetails />} />

              <Route
                path="/subscription-management"
                element={<PrivateRoute element={<SubMngmt />} />} // Protect the route with PrivateRoute
              />

              <Route
                path="/myai"
                element={<PrivateRoute element={<ChatInterface />} />}
              />
              <Route
                path="/myai1"
                element={
                  <PrivateRoute element={<ChatInterface chatBotId="1" />} />
                }
              />
              <Route
                path="/myai2"
                element={
                  <PrivateRoute element={<ChatInterface chatBotId="2" />} />
                }
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </main>
        </div>
      </Router>
    </Authenticator.Provider>
  );
}
